/* body > #root > div {
  height: 100vh;
} */

.App {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Login-form {
  min-height: 500px;
  min-width: 350px;
  background-color: beige;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 5px;
  margin: 20px;
}


.Logo-container {
  height: 180px;
  border: 5px solid cadetblue;
  background-color: blanchedalmond;
  border-radius: 5px;
  box-shadow: 0px 0px 5px;
  margin-top: 30px;
}

.Profile-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 20px;
}


.styled-button {
  height: 60px;
  max-width: 320px;
  background: #7cb5b5;
  border: 2px solid #7cb5b5;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  margin: 15px;
  width: 100%;
}


.styled-button:hover {
  opacity: 0.7;
  cursor: pointer;
}
.styled-buttonm:active {
  opacity: 0.2;
}

.User-icon {
  width: 150px;
  height: 130px;
  margin-top: 30px;
}


.Key-text{
  color: blue;
}

.Value-text{
  color: green;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  display: inline-block;
}

.text-container{
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
}